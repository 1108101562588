import axios from '@/common/axios'
import qs from 'qs'

export function addDeviceSealEnable (data) {
  return axios({
    method: 'post',
    url: '/universal/deviceSealEnable/add',
    data: qs.stringify(data)
  })
}

export function deleteDeviceSealEnable (id) {
  return axios({
    method: 'delete',
    url: '/universal/deviceSealEnable/delete/' + id
  })
}

export function updateDeviceSealEnable (data) {
  return axios({
    method: 'put',
    url: '/universal/deviceSealEnable/update',
    data: qs.stringify(data)
  })
}

export function selectDeviceSealEnableInfo (id) {
  return axios({
    method: 'get',
    url: '/universal/deviceSealEnable/info/' + id
  })
}

export function selectDeviceSealEnableList (query) {
  return axios({
    method: 'get',
    url: '/universal/deviceSealEnable/list',
    params: query
  })
}

export function reviewDeviceSealEnable (data) {
  return axios({
    method: 'put',
    url: '/universal/deviceSealEnable/review',
    data: qs.stringify(data)
  })
}
