<template>
  <div id="deviceSealEnable">
    <el-dialog
      :title="deviceSealEnableFormTitle"
      width="680px"
      :visible.sync="deviceSealEnableDialogVisible"
      :close-on-click-modal="false"
      @close="deviceSealEnableDialogClose"
    >
      <el-form
        ref="deviceSealEnableFormRef"
        :model="deviceSealEnableForm"
        :rules="deviceSealEnableFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编号" prop="deviceNo">
              <el-select
                v-model="deviceSealEnableForm.deviceNo"
                placeholder="请选择设备"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
                @change="devChange"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.deviceNo"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input
                v-model="deviceSealEnableForm.deviceName"
                placeholder="请输入设备名称"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号" prop="deviceSpec">
              <el-input
                v-model="deviceSealEnableForm.deviceSpec"
                placeholder="请输入设备型号"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" style="height: 62.8px;">
            <el-form-item label="申请类型" prop="applyType">
              <el-radio-group
                v-model="deviceSealEnableForm.applyType"
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              >
                <el-radio :label="1">
                  封存
                </el-radio>
                <el-radio :label="2">
                  启用
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封存/启用原因" prop="reason">
              <el-input
                v-model="deviceSealEnableForm.reason"
                placeholder="请输入封存/启用原因"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="封存/启用状态" prop="state">
              <el-input
                v-model="deviceSealEnableForm.state"
                placeholder="请输入封存/启用状态"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              />
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="封存/启用地点" prop="place">
              <el-input
                v-model="deviceSealEnableForm.place"
                placeholder="请输入封存/启用地点"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="deviceSealEnableForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="deviceSealEnableFormTitle !== '新增设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '修改设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="审批结果" prop="approverResult">
              <el-radio-group
                v-model="deviceSealEnableForm.reviewerResult"
                :disabled="deviceSealEnableFormTitle !== '审批设备封存（启用）申请单'
                  && deviceSealEnableFormTitle !== '设备封存（启用）申请单详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="deviceSealEnableDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deviceSealEnableFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备名称">
        <el-input v-model="searchForm.deviceName" placeholder="请输入设备名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['SEAL_ENABLE_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="deviceSealEnablePage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="deviceName" label="设备名称" />
      <el-table-column prop="deviceSpec" label="设备型号" />
      <el-table-column prop="deviceNo" label="设备编号" />
      <el-table-column label="申请类型">
        <template slot-scope="scope">
          <span v-if="scope.row.applyType === 1">封存</span>
          <span v-if="scope.row.applyType === 2">启用</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="封存/启用原因" />
      <!-- <el-table-column prop="state" label="封存/启用状态" /> -->
      <el-table-column prop="place" label="封存/启用地点" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="applyDept" label="申请部门" />
      <el-table-column prop="applicant" label="申请人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.applicantDate">{{ scope.row.applicantDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="审批人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerDate">{{ scope.row.reviewerDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审批结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewerResult === 1">通过</span>
          <span v-if="scope.row.reviewerResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['SEAL_ENABLE_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['SEAL_ENABLE_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['SEAL_ENABLE_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            审批
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="deviceSealEnablePage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addDeviceSealEnable,
  deleteDeviceSealEnable,
  updateDeviceSealEnable,
  selectDeviceSealEnableInfo,
  selectDeviceSealEnableList,
  reviewDeviceSealEnable
} from '@/api/universal/deviceSealEnable'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      deviceSealEnableDialogVisible: false,
      deviceSealEnableFormTitle: '',
      deviceSealEnableForm: {
        id: '',
        deviceName: '',
        deviceSpec: '',
        deviceNo: '',
        applyType: '',
        reason: '',
        state: '',
        place: '',
        remarks: '',
        reviewerResult: '',
        taskId: ''
      },
      deviceSealEnableFormRules: {
        deviceName: [{ required: true, message: '设备名称不能为空', trigger: ['blur', 'change']}]
      },
      deviceSealEnablePage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceName: ''
      },
      devList: []
    }
  },
  created () {
    selectDeviceSealEnableList(this.searchForm).then(res => {
      this.deviceSealEnablePage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    deviceSealEnableDialogClose () {
      this.$refs.deviceSealEnableFormRef.resetFields()
    },
    deviceSealEnableFormSubmit () {
      if (this.deviceSealEnableFormTitle === '设备封存（启用）申请单详情') {
        this.deviceSealEnableDialogVisible = false
        return
      }
      this.$refs.deviceSealEnableFormRef.validate(async valid => {
        if (valid) {
          if (this.deviceSealEnableFormTitle === '新增设备封存（启用）申请单') {
            await addDeviceSealEnable(this.deviceSealEnableForm)
          } else if (this.deviceSealEnableFormTitle === '修改设备封存（启用）申请单') {
            await updateDeviceSealEnable(this.deviceSealEnableForm)
          } else if (this.deviceSealEnableFormTitle === '审批设备封存（启用）申请单') {
            await reviewDeviceSealEnable(this.deviceSealEnableForm)
          }
          this.deviceSealEnablePage = await selectDeviceSealEnableList(this.searchForm)
          this.deviceSealEnableDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.deviceSealEnableFormTitle = '新增设备封存（启用）申请单'
      this.deviceSealEnableDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteDeviceSealEnable(row.id)
        if (this.deviceSealEnablePage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.deviceSealEnablePage = await selectDeviceSealEnableList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.deviceSealEnableFormTitle = '修改设备封存（启用）申请单'
      this.deviceSealEnableDialogVisible = true
      this.selectDeviceSealEnableInfoById(row.id)
    },
    handleReview (index, row) {
      this.deviceSealEnableFormTitle = '审批设备封存（启用）申请单'
      this.deviceSealEnableDialogVisible = true
      this.selectDeviceSealEnableInfoById(row.id)
    },
    handleInfo (index, row) {
      this.deviceSealEnableFormTitle = '设备封存（启用）申请单详情'
      this.deviceSealEnableDialogVisible = true
      this.selectDeviceSealEnableInfoById(row.id)
    },
    selectDeviceSealEnableInfoById (id) {
      selectDeviceSealEnableInfo(id).then(res => {
        this.deviceSealEnableForm.id = res.id
        this.deviceSealEnableForm.deviceName = res.deviceName
        this.deviceSealEnableForm.deviceSpec = res.deviceSpec
        this.deviceSealEnableForm.deviceNo = res.deviceNo
        this.deviceSealEnableForm.applyType = res.applyType
        this.deviceSealEnableForm.reason = res.reason
        this.deviceSealEnableForm.state = res.state
        this.deviceSealEnableForm.place = res.place
        this.deviceSealEnableForm.remarks = res.remarks
        this.deviceSealEnableForm.reviewerResult = res.reviewerResult
        this.deviceSealEnableForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectDeviceSealEnableList(this.searchForm).then(res => {
        this.deviceSealEnablePage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectDeviceSealEnableList(this.searchForm).then(res => {
        this.deviceSealEnablePage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectDeviceSealEnableList(this.searchForm).then(res => {
        this.deviceSealEnablePage = res
      })
    },
    devChange (value) {
      if (value) {
        let dev = this.devList.find(item => item.deviceNo === value)
        this.deviceSealEnableForm.deviceName = dev.deviceName
        this.deviceSealEnableForm.deviceSpec = dev.spec
      } else {
        this.deviceSealEnableForm.deviceName = ''
        this.deviceSealEnableForm.deviceSpec = ''
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['SEAL_ENABLE_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
